import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../assets/sass/main.scss';

class LayoutGiveaway extends Component {
  constructor() {
    super();
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.setState({ isPreloaded: false });


    //   const today = new Date();
    //   const daysUntil = 18 - today.getDate();

    //   document.querySelector("#daysUntil").innerHTML = daysUntil;
    // }, 100);
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
          <>
            <Helmet>
              <html lang="en" />
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
              {/* Primary Meta Tags */}
              <title>Crafted - Join for a chance to win a Howebrew Starter Kit from Northern Brewer</title>
              <meta name="title" content="Crafted - A newsletter for craft beer lovers - win a Homebrew Stater Kit" />
              <meta name="description" content="Join for a chance to win a Howebrew Starter Kit. Each week we tell the story of a great beer and the master that made it." />
              {/* Open Graph / Facebook */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://joincrafted.com" />
              <meta property="og:title" content="Crafted - A newsletter for craft beer lovers - win a Homebrew Stater Kit" />
              <meta property="og:description" content="Join for a chance to win a Howebrew Starter Kit. Each week we tell the story of a great beer and the master that made it." />
              <meta property="og:image" content="https://www.joincrafted.com/assets/images/brew-share-enjoy-homebrew-starter-kit_no-testing_x1400.jpg" />
              {/* Twitter */}
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://joincrafted.com" />
              <meta property="twitter:title" content="Crafted - A newsletter for craft beer lovers - win a Homebrew Stater Kit" />
              <meta property="twitter:description" content="Join for a chance to win a Howebrew Starter Kit. Each week we tell the story of a great beer and the master that made it." />
              <meta property="twitter:image" content="https://www.joincrafted.com/assets/images/brew-share-enjoy-homebrew-starter-kit_no-testing_x1400.jpg" />
              <link rel="icon" href="favicon.ico" />
            </Helmet>
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
       );
  }
}

LayoutGiveaway.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutGiveaway;
