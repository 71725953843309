import React from 'react';

import LayoutGiveaway from '../components/LayoutGiveaway';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import LogoFull from '../components/LogoFull';
import { Link } from "gatsby"

const IndexPage = () => (
  <LayoutGiveaway>
    <Header />
    <div>
        <div className="master-container auto-height">
          <main className="form-main auto-height" role="main">
            <div style={{maxWidth: '615px'}} className="content-left auto-height d-flex flex-column justify-content-center align-items-center">
              <div className="content-left-inner d-flex flex-column align-items-center">
                <LogoFull></LogoFull>
                <h1 className='d-none'>Crafted Giveaway</h1>
                <p className="h2-crafted my-2 my-sm-4 text-center">This giveaway has ended.</p>
                <p className="h2-crafted my-2 my-sm-4 text-center">All Crafted members had a chance to win a <a href="https://www.northernbrewer.com/collections/5-gallon-homebrew-kits/products/brew-share-enjoy-homebrew-starter-kit" target="_blank" rel="noreferrer">Brew Share Enjoy&#174; Homebrew Starter Kit</a>.</p>
                <p className="h2-crafted my-2 my-sm-4 text-center"><strong>Subscribe to our weekly newsletter for a chance to win future giveaways.</strong></p>

                <div className="mb-1 auto-height">
                <EmailForm autofocus={true} />
                </div>
                <img className="my-5" src="/assets/images/brew-share-enjoy-homebrew-starter-kit_no-testing_x1400.jpg" width={320} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block', borderRadius: '4px', border: '4px solid rgba(61, 64, 91, 0.7)', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)'}} />

                {/*
                <p className="h2-crafted my-2 my-sm-4 text-center">We'll pick the winner in <strong><span id="daysUntil">7</span> days</strong>. Make sure to subscribe before Monday, October 19 for a chance to win!</p>
                <div className="mt-2 mt-sm-4 text-center text-small">Giveaway sponsored generously by <img className="d-inline-block" src="/assets/images/northern-brewer-website-header-logo_c70dfcd2-31c1-4cbc-877a-8c42a5cea4e3.svg" style={{height: '32px', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} /></div>

                <p className="mb-3 mb-sm-4 p-3" style={{border: 'solid 2px rgba(61, 64, 91, 0.7)', borderRadius: '4px', backgroundColor: 'rgb(243, 243, 247)', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)', maxWidth: '505px', margin: 'auto'}}><strong>Limited Offer!</strong> Subscribe for a chance to win a <a href="https://www.northernbrewer.com/collections/5-gallon-homebrew-kits/products/brew-share-enjoy-homebrew-starter-kit" target="_blank" rel="noreferrer">Brew Share Enjoy&#174; Homebrew Starter Kit</a>.</p>
                */}
              </div>
            </div>
          </main>
        </div>
        <div id="overlay">
          <div id="loader-object"></div>
        </div>
        <footer className="mastfoot mt-auto">
          <div className="inner">
          </div>
        </footer>
      </div>
    <Footer />
  </LayoutGiveaway>
);

export default IndexPage;
